<template>
    <div id="product">
        <el-breadcrumb  separator="/">
            <el-breadcrumb-item  :to="{ path: '/' }" class="text-over clamp1 hidden-sm-and-down">หน้าแรก</el-breadcrumb-item>
            <el-breadcrumb-item   :to="{ path: '/project/1' }" class="text-over clamp1 hidden-sm-and-down">โครงการทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์</el-breadcrumb-item>
            <el-breadcrumb-item   :to="{ path: '/community/1' }" class="text-over clamp1">ศูนย์วัฒนธรรมไทลื้อวัดหย่วน ตำบลหย่วน อำเภอเชียงคำ จังหวัดพะเยา</el-breadcrumb-item>
            <el-breadcrumb-item   class="text-over clamp1">กระเป๋าสะพายไทลื้อผ้าตาแสง</el-breadcrumb-item>
        </el-breadcrumb>
        <h1 >ผลิตภัณฑ์ “ผลงานกระเป๋าคาดจากผ้าทอไทลื้อ” โดย คุณคงฤทธิ์</h1>
        <div class="image-title">
            <el-image
                :src="require('../../assets/product/cp1-5.jpg')" 
                fit="cover"
                :preview-src-list="previewImage"
            />
        </div>
        <div  class="detail">
            <h3 class="des">คำอธิบายผลงานผลิตภัณฑ์ที่ได้รับการพัฒนาจากโครงการ "ทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์"</h3>
            <p class="indent">
               กระเป๋าคาดอกใช้ผ้าห่มตาแสงในการทำโดยมีการเปลี่ยนโทนสีให้ดูทันสมัยเหมาะกับคนรุ่นใหม่  ผ้าห่มตาแสง  หรือผ้าลายสี่แป  ใช้เทคนิคการทอยกดอก  คือลายที่เกิดจากการเหยียบไม้ที่ยกเขาแยกเส้นยืนขึ้นลงทำให้เกิดลวดลาย  ไม้ที่เหยียบจะเรียงกันเป็นแพ 4 อัน ไทลื้อเรียกแพว่า แป  ใช้ฝ้ายสีขาวล้วนหรือสีดำ สีแดง สีขาว  ทำเส้นยืน และเส้นพุ่งสลับกัน  ทำให้เกิดเป็นลวดลายตารางสี่เหลี่ยม  ซึ่งชาวไทลื้อเรียกตารางสี่เหลี่ยมว่า “ตาแสง”  ผ้าห่มตาแสงหรือผ้าห่มสี่แป  บางท้องถิ่นเรียกผ้าห่มตาโก้ง  การทอผ้าลายสี่แปของชาวไทลื้อบ้านหย่วน  จะมีดอกเล็ก เรียกว่า สี่แปดอกพริก  และมีลายดอกใหญ่ เรียกว่า สี่แปดอกแก้ว ซึ่งลายสี่แปนี้นิยมทอเป็นลายผ้าห่ม  ผ้าห่มลายสี่แปจะมีทุกครัวเรือนของชาวไทลื้อในอดีต  
            </p>
 
            <h3>🎤ช่วงเราถาม-นักสร้างสรรค์ตอบ📞</h3>
            
            <p class="q-title">
                1. ผลิตภัณฑ์ที่เข้าร่วมโครงการนี้มีความเชื่อมโยงกับวัฒนธรรมชุมชนไทลื้อในด้านไหนอย่างไรบ้าง
            </p>
            <p class="mg-t-less">กระเป๋าคาดอกใช้ผ้าห่มตาแสงในการทำโดยมีการเปลี่ยนโทนสีให้ดูทันสมัยเหมาะกับคนรุ่นใหม่  ผ้าห่มตาแสง  หรือผ้าลายสี่แป  ใช้เทคนิคการทอยกดอก  คือลายที่เกิดจากการเหยียบไม้ที่ยกเขาแยกเส้นยืนขึ้นลงทำให้เกิดลวดลาย  ไม้ที่เหยียบจะเรียงกันเป็นแพ 4 อัน ไทลื้อเรียกแพว่า แป  ใช้ฝ้ายสีขาวล้วนหรือสีดำ สีแดง สีขาว  ทำเส้นยืน และเส้นพุ่งสลับกัน  ทำให้เกิดเป็นลวดลายตารางสี่เหลี่ยม  ซึ่งชาวไทลื้อเรียกตารางสี่เหลี่ยมว่า “ตาแสง”  ผ้าห่มตาแสงหรือผ้าห่มสี่แป  บางท้องถิ่นเรียกผ้าห่มตาโก้ง  การทอผ้าลายสี่แปของชาวไทลื้อบ้านหย่วน  จะมีดอกเล็ก เรียกว่า สี่แปดอกพริก  และมีลายดอกใหญ่ เรียกว่า สี่แปดอกแก้ว ซึ่งลายสี่แปนี้นิยมทอเป็นลายผ้าห่ม  ผ้าห่มลายสี่แปจะมีทุกครัวเรือนของชาวไทลื้อในอดีต</p>
            
            <el-row :gutter="40">
                <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp1-2.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
                 <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp1-3.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
            </el-row> 

            <p class="q-title">
                2. ประวัติที่มาที่ไปของการทำผลิตภัณฑ์  จุดเริ่มต้นมาได้อย่างไร    
            </p>
            <p class="mg-t-less">จุดเริ่มต้นจากเดิมจะทอผ้าเป็นผืน  และมีการตัดเย็บเป็นกระเป๋าแบบง่ายๆ  หลังจากที่ได้เข้าร่วมโครงการนี้ ได้ใช้ desidn  thinking  ออกแบบกระเป๋าให้ทันสมัยเหมาะกับกลุ่มลูกค้าโดยเฉพาะคนรุ่นใหม่</p>
            
                        
            <el-row :gutter="40">
                <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp1-4.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
                 <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp1-1.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
            </el-row> 

            <p class="q-title">
               3. เรื่องเล่าความประทับใจหรืออื่นๆ กับการเข้าร่วมโครงการครั้งนี้
            </p>
            <p class="mg-less">โครงการนี้ประทับใจที่สุดคือการขายของออนไลน์  ไม่ว่าจะเป็นการถ่ายรูป ถ่ายวีดีโอ  สร้างเพจขายของ  การไลฟ์สดขายของ  จากเดิมขายของแบบหน้าร้านเท่านั้น  ทำให้สามารถขยายฐานลูกค้าออกไปได้มาก</p>
            
            <div class="video">
                <video width="650" height="450"   controls>
                    <source :src="require('../../assets/video/cp1.mp4')" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>

            <p class="q-title">
               4. ช่องทางการติดต่อสำหรับสั่งซื้อสินค้า ศูนย์วัฒนธรรมไทลื้อวัดหย่วน
            </p>
            <p class=" mg-less"><a target="_blank" href="https://www.facebook.com/KR.Tailue">https://www.facebook.com/KR.Tailue</a></p>

            
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            previewImage:[
                require('../../assets/product/cp1-1.jpg'),
                require('../../assets/product/cp1-2.jpg'),
                require('../../assets/product/cp1-3.jpg'),
                require('../../assets/product/cp1-4.jpg'),
                require('../../assets/product/cp1-5.jpg'),
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/element-variables.scss";
#product{
  padding: 40px 10vw 40px 10vw;
   text-align: center;
   min-height: 20vw;
   h1{

      font-size: 30px;
      margin: 40px 0 10px 0;
    
    }
    .image-title {
        padding: 20px 0;
        .el-image{
            height: 400px;
        }
        @media (max-width: 767px) {
           .el-image{
                height: 200px;
            }
        }
    
    }
    .detail{
                text-align: justify;
        word-break: break-all;
        .indent{
            text-indent: 3em;
  
        }
        .q-title{
            font-weight: 600;
            margin-bottom: 10px;
            text-align: left;
        }
        h3{
           text-align: left; 
        }
        .des{
            text-align: center;
        }

        a{
            cursor: pointer;
            color:$--color-text-primary;
            text-decoration:none;
        }
        a:hover {
         color:$--color-primary;
        }
        
        .card-product{
            padding: 10px 0;
            overflow: hidden;
            cursor: pointer;
            text-align: center;
            .el-image{
                height: 300px;
                width: 100%;
            }
           

            @media (max-width: 767px) {
                height: auto;
                padding: 10px 0;
            }
        }

        .card-product:hover{
            p{
                color:$--color-primary;
            }
        }
        
       .video{
           text-align: center;
       }
    }
}
</style>