<template>
    <div id="product">
        <el-breadcrumb  separator="/">
            <el-breadcrumb-item  :to="{ path: '/' }" class="text-over clamp1 hidden-sm-and-down">หน้าแรก</el-breadcrumb-item>
            <el-breadcrumb-item   :to="{ path: '/project/1' }" class="text-over clamp1 hidden-sm-and-down">โครงการทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์</el-breadcrumb-item>
            <el-breadcrumb-item   :to="{ path: '/community/1' }" class="text-over clamp1">ศูนย์วัฒนธรรมไทลื้อวัดหย่วน ตำบลหย่วน อำเภอเชียงคำ จังหวัดพะเยา</el-breadcrumb-item>
            <el-breadcrumb-item   class="text-over clamp1">ถุงผ้าไทลื้อใส่แก้วเยติ</el-breadcrumb-item>
        </el-breadcrumb>
        <h1 >ผลิตภัณฑ์ “ถุงผ้าไทลื้อใส่แก้วเยติ” โดย คุณอุราพร</h1>
        <div class="image-title">
            <el-image
                :src="require('../../assets/product/cp4-6.jpg')" 
                fit="cover"
                :preview-src-list="previewImage"
            />
        </div>
        <div  class="detail">
            <h3 class="des">คำอธิบายผลงานผลิตภัณฑ์ที่ได้รับการพัฒนาจากโครงการ "ทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์"</h3>
            <p>
               ถุงใส่แก้วรักษ์โลก ตัดเย็บจากผ้าทอไทลื้อตกแต่งลายละเอียดด้วยการด้นมือและถักโครเชต์ ใช้เป็นแจกันดอกไม้ ใช้เป็นกระเช้า หรือจะทำเป็นที่แขวนไม้ประดับเก๋ๆก็ได้ 
            </p>

            <el-row :gutter="40">
                <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp4-2.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
                 <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp4-3.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
            </el-row> 

            <p>ถุงใส่แก้วเยติงานถักโครเชต์เพื่อคนรักสุขภาพ  จากงานถักพัฒนาต่อยอดด้วยการแต่งผ้าลายดอกสารภีผ้าประจำจังหวัดพะเยาจากช่างทอผ้าที่ศูนย์วัฒนธรรมไทลื้อวัดหย่วน ออกแบบลายผ้าโดย แม่ครูหทัยทิพย์ เชื้อสะอาด และคุณคงฤทธิ์  เชื้อสะอาด</p>
            <p class="mg-b-less"> สนใจสั่งทำตามสีที่ต้องการได้ โทร 089 559 1368</p>
            <div class="video">
                <video width="650" height="450"   controls>
                    <source :src="require('../../assets/video/cp4.mp4')" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
           
            <h3>ช่องทางการติดต่อหรือสนใจสั่งสินค้าได้ตามลิงค์นี้ค่ะ</h3>
            <a target="_blank" href="https://www.facebook.com/photo.php?fbid=4960626320718124&id=100003124140689&set=a.2655844824529630&source=57">https://www.facebook.com/P.Udomsunti</a>
            
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            previewImage:[
                require('../../assets/product/cp4-1.jpg'),
                require('../../assets/product/cp4-2.jpg'),
                require('../../assets/product/cp4-3.jpg'),
                require('../../assets/product/cp4-4.jpg'),
                require('../../assets/product/cp4-5.jpg'),
                require('../../assets/product/cp4-6.jpg'),
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/element-variables.scss";
#product{
  padding: 40px 10vw 40px 10vw;
   text-align: center;
   min-height: 20vw;
   h1{

      font-size: 30px;
      margin: 40px 0 10px 0;
    
    }
    .image-title {
        padding: 20px 0;
        .el-image{
            height: 400px;
        }
        @media (max-width: 767px) {
           .el-image{
                height: 200px;
            }
        }
    
    }
    .detail{
                text-align: justify;
        word-break: break-all;
        .indent{
            text-indent: 3em;
        }
        .q-title{
            font-weight: 600;
            margin-bottom: 10px;
            text-align: left; 
        }
        h3{
           text-align: left; 
        }
        .des{
            text-align: center;
        }
        a{
            cursor: pointer;
            color:$--color-text-primary;
            text-decoration:none;
        }
        a:hover {
         color:$--color-primary;
        }
        
        .card-product{
            padding: 10px 0;
            overflow: hidden;
            cursor: pointer;
            text-align: center;
            .el-image{
                height: 300px;
                width: 100%;
            }
           

            @media (max-width: 767px) {
                height: auto;
                padding: 10px 0;
            }
        }

        .card-product:hover{
            p{
                color:$--color-primary;
            }
        }
        
       .video{
           text-align: center;
       }
    }
}
</style>