<template>
    <div id="product">
        <el-breadcrumb  separator="/">
            <el-breadcrumb-item  :to="{ path: '/' }" class="text-over clamp1 hidden-sm-and-down">หน้าแรก</el-breadcrumb-item>
            <el-breadcrumb-item   :to="{ path: '/project/1' }" class="text-over clamp1 hidden-sm-and-down">โครงการทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์</el-breadcrumb-item>
            <el-breadcrumb-item   :to="{ path: '/community/1' }" class="text-over clamp1">ศูนย์วัฒนธรรมไทลื้อวัดหย่วน ตำบลหย่วน อำเภอเชียงคำ จังหวัดพะเยา</el-breadcrumb-item>
            <el-breadcrumb-item   class="text-over clamp1">หมวกผ้าฝ้ายปักลายอัตลักษณ์ไทลื้อ</el-breadcrumb-item>
        </el-breadcrumb>
        <h1 >ผลิตภัณฑ์ “หมวกผ้าฝ้ายปักลายอัตลักษณ์ไทลื้อ” โดย คุณพันธนา</h1>
        <div class="image-title">
            <el-image
                :src="require('../../assets/product/cp5-7.jpg')" 
                fit="cover"
                :preview-src-list="previewImage"
            />
        </div>
        <div  class="detail">
            <h3>🎤ช่วงเราถาม-นักสร้างสรรค์ตอบ📞</h3>
            
            <p class="q-title">
                1. ผลิตภัณฑ์ที่เข้าร่วมโครงการนี้มีความเชื่อมโยงกับวัฒนธรรมชุมชนไทลื้อในด้านไหนอย่างไรบ้าง
            </p>
            <p class="mg-t-less">ชาติพันธุ์ “ไทลื้อ” เป็นชาติพันธุ์ที่มีวัฒนธรรมการแต่งกายทำมาจากผ้าฝ้ายทอมือ ที่ผลิตจากฝ้ายแท้ 100% โดยมีกระบวนการตั้งแต่การปลูก การรีดเมล็ดฝ้าย การตีเนื้อฝ้ายให้เข้ากันจนเนียนนุ่ม การทำเส้นใย การย้อมด้วยสีห้อมธรรมชาติ การทอ  ผ้าฝ้ายไทลื้อจะมีคุณสมบัติพิเศษ โดยจะคนไทลื้อจะมีเทคนิคในการทอ หากผ้าที่จะนำมาเป็นผลิตภัณฑ์สวมใส่ในฤดูร้อน จะทอแบบไม่แน่นเพื่อให้มีการระบายอากาศ และซับเหงื่อในฤดูร้อนสวมใส่สบาย  ส่วนผลิตภัณฑ์ในฤดูหนาวจะเป็นผ้าทอเนื้อแน่นใส่แล้วกันหนาวได้  เนื่องจากฝ้ายมีความนุ่ม เมื่อทอให้เนื้อผ้าแน่นทำให้เกิดความอบอุ่น นี่คือสิ่งที่พิเศษสุดของผ้าฝ้ายไทลื้อ</p>
            
            <el-row :gutter="40">
                <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp5-1.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
                 <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp5-2.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
            </el-row> 

            <p class="q-title">
                2. ประวัติที่มาที่ไปของการทำผลิตภัณฑ์ของพี่พันธนา จุดเริ่มต้นมาได้อย่างไร    
            </p>
            <p class="mg-t-less">จากการที่ชุมชนบ้านธาตุหมู่ 2 อ.เชียงคำ จ.พะเยา มีกลุ่มผลิตผ้าทอไทลื้อเพื่อแปรรูปเป็นเครื่องนุ่งห่ม  ซึ่งทำให้มีเศษผ้าทอที่เหลือจากการทำผลิตภัณฑ์  ด้วยความรู้สึกรักและเสียดายเศษผ้าเหล่านั้นและต้องการถ่ายทอดวัฒนธรรมของชนเผ่าเพื่อการสืบทอดภูมิปัญญาผ้าทอ  จึงได้นำมาเย็บต่อกันด้วยฝีเย็บผ้าคนโบราณ เช่นลายกระดูกงู ,ลายตะขาบ, ลายจ่องเอวกบ เป็นต้น และเพื่อให้มีเอกลักษณ์ บนหมวกยังตกแต่งด้วยลายปักต้นจามจุรีที่สวยที่สุดในประเทศไทยที่อยู่ในชุมชนบ้านธาตุสบแวน</p>
            
                        
            <el-row :gutter="40">
                <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp5-4.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
                 <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp5-6.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
            </el-row> 

            <p class="q-title">
               3. เรื่องเล่าความประทับใจหรืออื่นๆ กับการเข้าร่วมโครงการครั้งนี้
            </p>
            <p class="mg-less">เป็นความโชคดีที่ได้ร่วมโครงการนี้  ทำให้ได้ความรู้  ประสบการณ์ หลายอย่างที่ยังไม่เคยรู้ เช่น การเรียนรู้เรื่องกระบวนการต่างๆในการผลิต ,การถ่ายภาพ,การทำเพจในโซเชี่ยลมีเดีย  และอื่นๆ จากทีมวิทยากร และนักศึกษาฝึกงานที่มีความเป็นกันเอง และอดทนต่อผู้เข้ารับการอบรมซึ่งส่วนใหญ่เป็นผู้สูงวัย   ทำให้ความตั้งใจที่จะสื่อสารการสืบทอดวัฒนธรรมลงบนหมวกผ้าทอไทลื้อ  ครั้งนี้สำเร็จไปด้วยดี</p>
            
            <div class="video">
                <video width="650" height="450"   controls>
                    <source :src="require('../../assets/video/cp5.mp4')" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>

            <p class="q-title">
               4. ช่องทางการติดต่อสอบถามหรือสั่งสินค้า
            </p>
            <p class=" mg-less"><a target="_blank" href="https://www.facebook.com/%E0%B8%9B%E0%B9%89%E0%B8%B2%E0%B8%A1%E0%B8%B2%E0%B8%A5%E0%B8%B5%E0%B8%9C%E0%B9%89%E0%B8%B2%E0%B8%97%E0%B8%AD%E0%B9%84%E0%B8%97%E0%B8%A5%E0%B8%B7%E0%B9%89%E0%B8%AD-%E0%B8%AD%E0%B9%80%E0%B8%8A%E0%B8%B5%E0%B8%A2%E0%B8%87%E0%B8%84%E0%B8%B3-571199100004928">https://www.facebook.com/ป้ามาลีผ้าทอไทลื้อ-อเชียงคำ</a></p>

            
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            previewImage:[
                require('../../assets/product/cp5-1.jpg'),
                require('../../assets/product/cp5-2.jpg'),
                require('../../assets/product/cp5-3.jpg'),
                require('../../assets/product/cp5-4.jpg'),
                require('../../assets/product/cp5-5.jpg'),
                require('../../assets/product/cp5-6.jpg'),
                require('../../assets/product/cp5-7.jpg')
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/element-variables.scss";
#product{
  padding: 40px 10vw 40px 10vw;
   text-align: center;
   min-height: 20vw;
   h1{

      font-size: 30px;
      margin: 40px 0 10px 0;
    
    }
    .image-title {
        padding: 20px 0;
        .el-image{
            height: 400px;
        }
        @media (max-width: 767px) {
           .el-image{
                height: 200px;
            }
        }
    
    }
    .detail{
                text-align: justify;
        word-break: break-all;
        .indent{
            text-indent: 3em;
        }
        .q-title{
            font-weight: 600;
            margin-bottom: 10px;
            text-align: left; 
        }
        h3{
           text-align: left; 
        }

        a{
            cursor: pointer;
            color:$--color-text-primary;
            text-decoration:none;
        }
        a:hover {
         color:$--color-primary;
        }
        
        .card-product{
            padding: 10px 0;
            overflow: hidden;
            cursor: pointer;
            text-align: center;
            .el-image{
                height: 300px;
                width: 100%;
            }
           

            @media (max-width: 767px) {
                height: auto;
                padding: 10px 0;
            }
        }

        .card-product:hover{
            p{
                color:$--color-primary;
            }
        }
        
       .video{
           text-align: center;
       }
    }
}
</style>