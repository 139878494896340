import { render, staticRenderFns } from "./ProductCP3.vue?vue&type=template&id=17db79f5&scoped=true&"
import script from "./ProductCP3.vue?vue&type=script&lang=js&"
export * from "./ProductCP3.vue?vue&type=script&lang=js&"
import style0 from "./ProductCP3.vue?vue&type=style&index=0&id=17db79f5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17db79f5",
  null
  
)

export default component.exports