<template>
    <div id="product">
        <el-breadcrumb  separator="/">
            <el-breadcrumb-item  :to="{ path: '/' }" class="text-over clamp1 hidden-sm-and-down">หน้าแรก</el-breadcrumb-item>
            <el-breadcrumb-item   :to="{ path: '/project/1' }" class="text-over clamp1 hidden-sm-and-down">โครงการทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์</el-breadcrumb-item>
            <el-breadcrumb-item   :to="{ path: '/community/1' }" class="text-over clamp1">ศูนย์วัฒนธรรมไทลื้อวัดหย่วน ตำบลหย่วน อำเภอเชียงคำ จังหวัดพะเยา</el-breadcrumb-item>
            <el-breadcrumb-item   class="text-over clamp1">ถุงย่ามยกมุกไทลื้อ</el-breadcrumb-item>
        </el-breadcrumb>
        <h1 >ผลิตภัณฑ์ “ถุงย่ามยกมุกไทลื้อ” โดย คุณกุลธิดา</h1>
        <div class="image-title">
            <el-image
                :src="require('../../assets/product/cp2-3.jpg')" 
                fit="cover"
                :preview-src-list="previewImage"
            />
        </div>
        <div  class="detail">
            <h3 class="des">คำอธิบายผลงานผลิตภัณฑ์ที่ได้รับการพัฒนาจากโครงการ "ทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์"</h3>
             <div class="video">
                <video width="700" height="450"   controls>
                    <source :src="require('../../assets/video/cp2.mp4')" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
            <p class="indent">
               ถุงย่ามยกมุกไทลื้อ ทำมาจากผ้าฝ้ายทอมือ โดยการนำลวดลายแซงแถบสีต่างๆ จากถุงย่ามหมากแดง ที่เป็นถุงย่ามไทลื้อลายดั้งเดิมของชาวไทลื้อ นั้นจะไม่มีลายยกมุก จึงได้พัฒนาผลิตภัณฑ์ใหม่เป็นถุงย่ามยกมุกไทลื้อขึ้น ก็โดยการนำเอาลวดลายแซงแถบสีต่างๆ มาทอสลับลายมุกตำตา ลายมุกไทลื้อ เสร็จแล้วเย็บเป็นถุงย่าม มี 2 แบบ ของผู้ชาย สำหรับของผู้หญิงก็ตกแต่งด้วยพู่  เพื่อความเก๋ไก๋ น่ารัก เหมาะกับยุคสมัย โดยยังคงความเป็นอัตลักษณ์ของชาวไทลื้ออยู่ด้วย  
            </p>
 
          <el-row :gutter="40">
                <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp2-1.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
                 <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp2-2.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
            </el-row> 

            
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            previewImage:[require('../../assets/product/cp2-1.jpg'),require('../../assets/product/cp2-2.jpg'),require('../../assets/product/cp2-3.jpg')]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/element-variables.scss";
#product{
  padding: 40px 10vw 40px 10vw;
   text-align: center;
   min-height: 20vw;
   h1{

      font-size: 30px;
      margin: 40px 0 10px 0;
    
    }
    .image-title {
        padding: 20px 0;
        .el-image{
            height: 400px;
        }
        @media (max-width: 767px) {
           .el-image{
                height: 200px;
            }
        }
    
    }
    .detail{
                text-align: justify;
        word-break: break-all;
        .indent{
            text-indent: 3em;
        }
        .q-title{
            font-weight: 600;
            margin-bottom: 10px;
            text-align: left; 
        }
        h3{
           text-align: left; 
        }
        .des{
            text-align: center;
        }
        a{
            cursor: pointer;
            color:$--color-text-primary;
            text-decoration:none;
        }
        a:hover {
         color:$--color-primary;
        }
        
        .card-product{
            padding: 10px 0;
            overflow: hidden;
            cursor: pointer;
            text-align: center;
            .el-image{
                height: 300px;
                width: 100%;
            }
           

            @media (max-width: 767px) {
                height: auto;
                padding: 10px 0;
            }
        }

        .card-product:hover{
            p{
                color:$--color-primary;
            }
        }
        
       .video{
           text-align: center;
       }
    }
}
</style>