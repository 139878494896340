<template>
    <div id="product">
        <el-breadcrumb  separator="/">
            <el-breadcrumb-item  :to="{ path: '/' }" class="text-over clamp1 hidden-sm-and-down">หน้าแรก</el-breadcrumb-item>
            <el-breadcrumb-item   :to="{ path: '/project/1' }" class="text-over clamp1 hidden-sm-and-down">โครงการทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์</el-breadcrumb-item>
            <el-breadcrumb-item   :to="{ path: '/community/1' }" class="text-over clamp1">ศูนย์วัฒนธรรมไทลื้อวัดหย่วน ตำบลหย่วน อำเภอเชียงคำ จังหวัดพะเยา</el-breadcrumb-item>
            <el-breadcrumb-item   class="text-over clamp1">หมอนอิงไทลื้อ</el-breadcrumb-item>
        </el-breadcrumb>
        <h1 >ผลิตภัณฑ์ “มุมโปรด โอบอุ่น หมอนอิงไทลื้อ” โดย คุณหทัยทิพย์ เชื้อสะอาด</h1>
        <div class="image-title">
            <el-image
                :src="require('../../assets/product/cp6-6.jpg')" 
                fit="cover"
                :preview-src-list="previewImage"
            />
        </div>
        <div  class="detail">
            <h3>🎤ช่วงเราถาม-นักสร้างสรรค์ตอบ📞</h3>
            
            <p class="q-title">
                1. ผลิตภัณฑ์ที่เข้าร่วมโครงการนี้มีความเชื่อมโยงกับวัฒนธรรมชุมชนไทลื้อในด้านไหนอย่างไรบ้าง
            </p>
            <p class="mg-t-less">ผ้าลายน้ำไหลผักแว่นเป็นลายผ้าที่ใช้เทคนิคการเกาะหรือล้วงซึ่งเป็นเทคนิคที่นิยมทอลวดลายบน   ผืนผ้าของชาติพันธุ์ไทลื้อ  สำหรับผ้าลายน้ำไหลผักแว่นเป็นลายผ้าที่ปรากฏในผ้าซิ่นเครื่องแต่งกายของสตรี  ชาวไทลื้อในอำเภอเชียงคำ และลายน้ำไหลผักแว่นยังเป็นลายเอกลักษณ์เฉพาะถิ่น</p>
            
            <el-row :gutter="40">
                <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp6-1.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
                 <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp6-2.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
            </el-row> 

            <p class="q-title">
                2. ประวัติที่มาที่ไปของการทำ ผลิตภัณฑ์  จุดเริ่มต้นมาได้อย่างไร    
            </p>
            <p class="mg-t-less">ผ้าลายน้ำไหลผักแว่นเป็นลายผ้าที่อยู่ในผ้าซิ่น เพื่อให้เป็นที่รู้จักอย่างกว้างขวางของลวดลายดังกล่าวและยังสื่อถึงเอกลักษณ์เฉพาะถิ่นรวมถึงสถานการณ์ปัจจุบัน โรคโควิด-19  ทำให้คนต้องทำงานที่บ้านและมีเวลาอยู่กับบ้านมากขึ้น  จึงอยากสร้างมุมโปรดที่มีทุนทางวัฒนธรรมผสมผสานกับความร่วมสมัยโดยใช้ผ้า   ลายน้ำไหลผักแว่นมาทำปลอกหมอนอิงซึ่งใช้ขนาดตามมาตรฐานที่นิยมใช้กัน คือขนาด 16 นิ้ว X16 นิ้ว</p>
            
                        
            <el-row :gutter="40">
                <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp6-3.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
                 <el-col  :xs="24" :sm="12" >
                    <div class="card-product">
                        <el-image
                            :src="require('../../assets/product/cp6-4.jpg')" 
                            fit="cover"
                            :preview-src-list="previewImage"
                        />
                    </div>
                </el-col>
            </el-row> 

            <p class="q-title">
               3. เรื่องเล่าความประทับใจหรืออื่นๆกับการเข้าร่วมโครงการครั้งนี้
            </p>
            <p class="mg-less ">🎤 ได้รับความรู้จากการวิเคราะห์ต้นทุนทางวัฒนธรรมนำมาสร้างผลิตภัณฑ์และเทคนิคการถ่ายภาพ  การสร้างภาพผลิตภัณฑ์ให้เกิดความน่าสนใจ การนำเสนอผลิตภัณฑ์ และช่องทางการจำหน่าย</p>
            <p class="mg-less ">🎤 วิทยากรมีความรู้ความสามารถ อัธยาศัยดีมากให้ความเป็นกันเองกับผู้เข้าอบรม</p>
            
            <div class="video">
                <video width="650" height="450"   controls>
                    <source :src="require('../../assets/video/cp6.mp4')" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>

            <p class="q-title">
               4. ช่องทางการติดต่อสำหรับสั่งซื้อสินค้า ศูนย์วัฒนธรรมไทลื้อวัดหย่วน
            </p>
            <p class=" mg-less"><a target="_blank" href="https://www.facebook.com/KR.Tailue">https://www.facebook.com/KR.Tailue</a></p>

            
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            previewImage:[
                require('../../assets/product/cp6-1.jpg'),
                require('../../assets/product/cp6-2.jpg'),
                require('../../assets/product/cp6-3.jpg'),
                require('../../assets/product/cp6-4.jpg'),
                require('../../assets/product/cp6-5.jpg'),
                require('../../assets/product/cp6-6.jpg'),
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/element-variables.scss";
#product{
  padding: 40px 10vw 40px 10vw;
   text-align: center;
   min-height: 20vw;
   h1{

      font-size: 30px;
      margin: 40px 0 10px 0;
    
    }
    .image-title {
        padding: 20px 0;
        .el-image{
            height: 400px;
        }
        @media (max-width: 767px) {
           .el-image{
                height: 200px;
            }
        }
    
    }
    .detail{
                text-align: justify;
        word-break: break-all;
        .indent{
            text-indent: 3em;
        }
        .q-title{
            font-weight: 600;
            margin-bottom: 10px;
            text-align: left; 
        }
        h3{
           text-align: left; 
        }

        a{
            cursor: pointer;
            color:$--color-text-primary;
            text-decoration:none;
        }
        a:hover {
         color:$--color-primary;
        }
        
        .card-product{
            padding: 10px 0;
            overflow: hidden;
            cursor: pointer;
            text-align: center;
            .el-image{
                height: 300px;
                width: 100%;
            }
           

            @media (max-width: 767px) {
                height: auto;
                padding: 10px 0;
            }
        }

        .card-product:hover{
            p{
                color:$--color-primary;
            }
        }
        
       .video{
           text-align: center;
       }
    }
}
</style>