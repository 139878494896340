<template>
  <div id="product">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
        :to="{ path: '/' }"
        class="text-over clamp1 hidden-sm-and-down"
        >หน้าแรก</el-breadcrumb-item
      >
      <el-breadcrumb-item
        :to="{ path: '/project/1' }"
        class="text-over clamp1 hidden-sm-and-down"
        >โครงการทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์</el-breadcrumb-item
      >
      <el-breadcrumb-item
        :to="{ path: '/product/ng1' }"
        class="text-over clamp1"
        >ชุมชนแม่อิง ตำบลแม่อิง อำเภอภูกามยาว จังหวัดพะเยา</el-breadcrumb-item
      >
      <el-breadcrumb-item class="text-over clamp1"
        >การออกแบบชุดฟ้อนท์ตัวอักษร</el-breadcrumb-item
      >
    </el-breadcrumb>
    <h1>
      ชุดฟ้อนท์ตัวอักษรไทย TH TermM UP และอังกฤษ EN TermM UP
      เพื่อประโยชน์เชิงพาณิชย์ของผู้ประกอบการและวิสาหกิจชุมชน
    </h1>
    <p class="writtenBy">
      <b>เขียนและให้ข้อมูลโดย</b> นายเอกรินทร์ ลัทธศักย์ศิริ <br />
      ปราชญ์ชาวบ้านอำเภอภูกามยาว จังหวัดพะเยา ผู้แทนชุมชนตำบลแม่อิง<br />
      <b>เรียบเรียงและเพิ่มเติมโดย</b> ผู้ช่วยศาสตราจารย์ธัญญพัทธ์
      มุ่งพัฒนสุนทร<br />
      อาจารย์ประจำคณะรัฐศาสตร์และสังคมศาสตร์ มหาวิทยาลัยพะเยา<br />
      เผยแพร่วันที่ 26 พฤษภาคม พ.ศ.2565
    </p>
    <div class="video">
      <video width="650" height="450" controls>
        <source :src="require('../../assets/video/ng1.mp4')" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>

    <div class="detail">
      <p class="q-title">
        1. ที่มาของการที่คิดสร้างชุดฟ้อนท์สำหรับใช้งานเชิงพาณิชย์
      </p>
      <p class="indent mg-t-less mg-b-less">
        การออกแบบผลิตภัณฑ์และบรรจุภัณฑ์เพื่อใช้ในชุมชน
        มักพบกับปัญหาและอุปสรรคจากลิขสิทธิ์ของรูปภาพและแบบอักษร
        รวมถึงวิสาหกิจชุมชนในพื้นที่ตำบลแม่อิง
        ซึ่งต้องหยิบยืมรูปแบบฟ้อนท์ตัวอักษรสำเร็จรูปจากที่อื่นมาใช้
        แต่ไม่สามารถจดแจ้งหรือขึ้นทะเบียนเป็นทรัพย์สินทางปัญญาของชุมชนได้
        อีกทั้งยังสุ่มเสี่ยงที่จะถูกฟ้องร้องจากการละเมิดลิขสิทธิ์
        หรือทรัพย์สินทางปัญญาของหน่วยงาน หรือนักออกแบบท่านอื่นๆ
      </p>
      <p class="indent mg-t-less mg-b-less">
        ชุดแบบอักษร TH TermM UP และ EN TermM UP
        เป็นผลงานการออกแบบชุดอักษรที่ได้รับความร่วมมือจากทางคณะรัฐศาสตร์และสังคมศาสตร์
        มหาวิทยาลัยพะเยา โดย หัวหน้าโครงการ ผู้ช่วยศาสตราจารย์ธัญญพัทธ์
        มุ่งพัฒนสุนทร
        ได้เข้ามาร่วมจัดทำโครงการวิจัยและพัฒนาเพื่อประโยชน์แก่ชุมชน
        ภายใต้หัวข้อโครงการวิจัยและพัฒนาเรื่อง
        “ทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์” โดยได้รับทุนสนับสนุนจาก
        หน่วยขับเคลื่อนนวัตกรรมเพื่อสังคม (Social Innovation Driving Unit: SID)
        ประจำพื้นที่ภาคเหนือตอนบน 2 มหาวิทยาลัยพะเยา ประจำปีงบประมาณ พ.ศ. 2564
        ด้วยวัตถุประสงค์หลักในการพัฒนานวัตกรรมทางสังคมให้กับกลุ่มผู้ประกอบการหรือวิสาหกิจชุมชน
        จนนำมาสู่การสร้างชุดฟ้อนท์ตัวอักษรทั้งภาษาไทยและภาษาอังกฤษให้กับผู้ประกอบการหรือวิสาหกิจในชุมชนตำบลแม่อิงได้เป็นชุมชนแรกที่ได้ใช้ชุดฟ้อนท์ตัวอักษรเหล่านี้ในเชิงพาณิชย์
      </p>
      <p class="indent mg-t-less mg-b-less">
        โดยเริ่มต้นจากการพูดคุยปรึกษาหารือร่วมกับปราชญ์ชุมชนคุณเอกรินทร์
        ลัทธศักย์ศิริ
        ถึงประเด็นปัญหาในการใช้รูปแบบตัวอักษรในเชิงพาณิชย์บนผลิตภัณฑ์
        หรือบรรจุภัณฑ์ ฯลฯ ที่มักจะติดปัญหาในเรื่องของลิขสิทธิ์หรือสิทธิบัตร
        ดังนั้น การที่ผลิตภัณฑ์จะจำหน่ายได้และประสบความสำเร็จในตลาดนั้น
        จำเป็นอย่างยิ่งที่จะต้องสร้างจุดเด่นหรือจุดขายที่แตกต่างและน่าสนใจ
        อีกทั้งจะต้องพึ่งพาบรรจุภัณฑ์ที่มีเอกลักษณ์ที่โดยเด่นและไม่ติดปัญหาในเรื่องการใช้งานเพื่อดึงดูดใจให้ผู้บริโภคตัดสินใจซื้อ
        จึงนำมาสู่การออกแบบและสร้างชุดฟ้อนท์ตัวอักษร TH TermM UP และ EN TermM UP
        เพื่อเป็นทางเลือกและขจัดความกังวลในการใช้งานชุดแบบอักษรบนผลิตภัณฑ์และบรรจุภัณฑ์ของชุมชน
        อีกทั้งเป็นการแสดงถึงเอกลักษณ์และอัตลักษณ์ของชุมชนที่ตั้งอยู่ในจังหวัดพะเยา
        และยังเป็นการสร้างและออกแบบชุดฟ้อนตัวอักษรในนาม มหาวิทยาลัยพะเยา
        เป็นครั้งแรกอีกด้วย
      </p>

      <p class="q-title">2. กระบวนการสร้างชุดฟ้อนท์ตัวอักษรไทยและอังกฤษ</p>
      <p class="indent mg-t-less mg-b-less">
        เริ่มต้นจากการได้รับความอนุเคราะห์จากท่านอาจารย์เทิม มีเต็ม
        ผู้เชี่ยวชาญด้านภาษาตะวันออก ผู้ทรงคุณวุฒิกรมศิลปากร กระทรวงวัฒนธรรม
        เป็นสมาชิกกิตติมศักดิ์ของสยามสมาคมในพระบรมราชูปถัมภ์ และคุณเอกรินทร์
        ลัทธศักย์ศิริ ปราชญ์ชาวบ้านอำเภอภูกามยาว จังหวัดพะเยา
        บุคคลที่เป็นลูกศิษย์ทำงานใกล้ชิดกับปูชณียบุคคลแห่งชาติในด้านตัวอักษรภาษาตะวันออกอย่างใกล้ชิด
        จึงนำมาสู่การให้ความอนุเคราะห์ไฟล์ต้นแบบตัวอักษรล้านนา
        และตัวอักษรสุโขทัยที่ท่านอาจารย์เทิมเป็นผู้ที่ถอดแบบตัวอักษรโบราณลงเทียบออกมาเป็นตัวอักษรในปัจจุบัน
        ด้วยการเขียนด้วยมือของท่านคัดลอกออกมา
      </p>
      <p class="indent mg-t-less mg-b-less">
        ขั้นต่อมาทางผู้ช่วยศาสตราจารย์ธัญญพัทธ์ มุ่งพัฒนสุนทร
        ได้ทำการประมวลความเชื่อมโยงระหว่างอัตลักษณ์ชุมชนตำบลแม่อิง
        ที่เป็นพื้นที่บริบทชุมชนอยู่สังคมวัฒนธรรมล้านนามายาวนาน
        และมีความผสมผสานกับความเป็นเมืองเหนือของประเทศไทยจากการปกครองมาตั้งแต่สมัยสุโขทัย
        จึงได้นำรูปแบบตัวอักษรโบราณที่ได้รับความอนุเคราะห์มาจากท่านอาจารย์เทิม
        มาออกแบบเป็นรูปแบบตัวอักษรที่มีความผสมผสานเชื่อมโยงระหว่างชุมชนแม่อิงเข้ากับรูปแบบตัวอักษรโบราณตามที่กล่าว
        โดยออกแบบให้ตัวอักษรยังคงพอมีเค้ารูปแบบอักษรเดิมที่ถอดออกมาจากศิลาจารึกโดยท่านอาจาย์เทิมและปรับให้มีความชดช้อยของตัวอักษรให้เปรียบเสมือนสายน้ำอิงที่เป็นแม่น้ำสายหลักของชุมชนแม่อิง
        อำเภอภูกามยาว จังหวัดพะเยา
        ที่เป็นสายน้ำหลักที่สำคัญของจังหวัดพะเยาในการหล่อเลี้ยงชีวิตของคนในหลาย
        ๆ ชุมชน ที่มีวิถีชีวิตอยู่ใกล้ชิดหรือเกี่ยวข้องกับสายน้ำแห่งนี้
        ตลอดแม่น้ำสายนี้มีความพิเศษตรงที่ไหลจากทางใต้ขึ้นเหนือเข้าสู่เขตจังหวัดเชียงราย
        และไหลลงสู่แม่น้ำโขงที่บ้านปากอิง อำเภอเชียงของ จังหวัดเชียงราย
      </p>
      <p class="indent mg-t-less mg-b-less">
        ในกระบวนการขั้นต่อมา
        คือการนำรูปแบบตัวอักษรเข้าสู่การทำเป็นตัวฟ้อนท์ที่สามารถดาวน์โหลดไปติดตั้งใช้กับโปรแกรมคอมพิวเตอร์ต่าง
        ๆ
        โดยในระหว่างการจัดทำได้มีการประสานและให้ความร่วมมือระหว่างกันทั้งในส่วนของนักวิจัย
        ชุมชน ตลอดจนปูชณียบุคคลทางด้านตัวอักษรตะวันออก
        จนออกมาเป็นรูปแบบฟ้อนท์ตัวอักษรทั้ง 2 แบบ
        และในขั้นตอนสุดท้ายได้จัดส่งยื่นขอลิขสิทธิ์และสิทธิบัตรผ่านหน่วยงานสถาบันนวัตกรรมถ่ายทอดเทคโนโลยีมหาวิทยาลัยพะเยา
        ในนามของมหาวิทยาลัยพะเยา โดยมีผู้ช่วยศาสตราจารย์ธัญญพัทธ์ มุ่งพัฒนสุนทร
        ได้ดำเนินการขอใช้ประโยชน์กับทางมหาวิทยาลัยเพื่อให้ชุมชนได้นำไปใช้ประโยชน์ในเชิงพาณิชย์ต่อไป
      </p>

      <p class="q-title">
        3. ประวัติความเป็นมาระหว่างปูชณียบุคคลด้านตัวอักษรตะวันออก
      </p>
      <div class="image-title">
        <el-image
          :src="require('../../assets/product/ng1-2.jpg')"
          fit="cover"
          :preview-src-list="previewImage"
        />
      </div>
      <p class="indent mg-t-less mg-b-less">
        ในช่วงปี พ.ศ. 2520 อาจารย์เทิม มีเต็ม เดินทางมาทำงานในจังหวัดพะเยา
        เพื่อร่วมงานกับ พระอุบาลีคุณูปมาจารย์ (ปวง ธมฺมปญฺโญ)
        พร้อมด้วยผู้ทรงคุณวุฒิอีกหลายท่าน อาทิ ศาสตราจารย์ ดร. ประเสริฐ ณ นคร
        เป็นต้น ในการสำรวจ รวบรวมศิลาจารึกโบราณที่พบตามแหล่งต่างๆของจังหวัดพะเยา
        ซึ่งเป็นหลักฐานและข้อมูลทางประวัติศาสตร์ที่สำคัญอย่างยิ่ง
        ที่ยังคงหลงเหลือและเก็บรักษาไว้ที่จังหวัดพะเยา
        ส่วนหนึ่งนำไปเก็บรักษาไว้ที่พิพิธภัณฑสถานแห่งชาติพระนคร
        และพิพิธภัณฑสถานแห่งชาติลำพูน ในขณะนั้น
        เพื่อปริวรรตจารึกโบราณของจังหวัดพะเยา รวมทั้งสิ้นจำนวน 113 หลัก
        โดยรวบรวมและตีพิมพ์เป็นหนังสือ “ประชุมจารึกเมืองพะเยา” จัดพิมพ์โดย
        สำนักพิมพ์มติชน เมื่อปี พ.ศ. 2538
      </p>
      <p class="indent mg-t-less mg-b-less">
        อาจารย์เทิม มีเต็ม ผู้ซึ่งอุทิศกำลังกาย กำลังสติปัญญา และอุทิศตน
        เพื่อเผยแพร่ความรู้ ทำงานด้านภาษาตะวันออก
        ทั้งด้านการสอนการถ่ายทอดความรู้
        และการปริวรรตภาษาตะวันออกโบราณเป็นภาษาไทยปัจจุบัน
        เป็นผู้รอบรู้ในสหวิทยาการ ที่เกี่ยวเนื่องและเอื้อประโยชน์ต่อชาติไทย
        เป็นแหล่งข้อมูลสำคัญทางประวัติศาสตร์ของชาติ อาจารย์เทิม มีเต็ม
        เป็นผู้มีความรู้แตกฉานทางด้านพระพุทธศาสนา ประวัติศาสตร์ โบราณคดี
        ขนบธรรมเนียมประเพณี โหราศาสตร์ คติชนวิทยา ตลอดจนศาสนพิธีและพิธีกรรมต่างๆ
        จึงเป็นบุคคลที่สมควรยกย่องและเป็นแบบอย่างในคุณูปการในหลายด้าน
        หลายสาขาวิชา โดยเฉพาะด้านประวัติศาสตร์และการศึกษาของชาติไทยเป็นสำคัญ
      </p>
      <p class="indent mg-t-less mg-b-less">
        อาจารย์เทิม มีเต็ม มีความเชี่ยวชาญในการอ่านวิเคราะห์
        อักษรโบราณประเภทต่างๆ และสามารถแปลภาษาตะวันออกเป็นภาษาไทยปัจจุบัน อาทิ
        ภาษาไทยโบราณ ภาษาบาลี ภาษามอญ ภาษาพม่า ภาษาไทยใหญ่ ฯลฯ เป็นต้น
        มีผลงานตีพิมพ์ปรากฏมากมาย อาทิ หนังสือประชุมศิลาจารึก ภาคต่างๆ
        จารึกในประเทศไทย เล่ม 1-5 จัดพิมพ์โดย กรมศิลปากร และหนังสือ ลานเก่า
        เล่าเรื่อง ซึ่งจัดพิมพ์โดย สยามสมาคมในพระบรมราชูปถัมภ์ เป็นต้น
      </p>
      <p class="indent mg-t-less mg-b-less">
        แม้ว่าจะมิได้เข้ารับการศึกษาระดับอุดมศึกษา แต่อาจารย์เทิม มีเต็ม
        มีอุตสาหะ โดยได้ศึกษาจากสำนักเรียนวัด และค้นคว้าด้วยตนเองมาตลอดทั้งชีวิต
        ต่อเนื่องยาวนานกว่า 55 ปี ด้วยผลงานที่ได้สั่งสมมาตลอดทั้งชีวิต
        จึงได้รับพระราชทานปริญญาศิลปศาสตรดุษฎีบัณฑิตกิตติมศักดิ์
        สาขาวิชาจารึกศึกษา มหาวิทยาลัยศิลปากร ประจำปี พ.ศ.2557
        นับเป็นปริญญาเป็นใบแรกและใบเดียวในชีวิตของท่าน
      </p>
      <p class="indent mg-t-less mg-b-less">
        ตลอดชีวิตของท่าน อาจารย์เทิม มีเต็ม ทำงานเกี่ยวอักษร ภาษา และเอกสารโบราณ
        โดยเฉพาะด้านจารึกภาษาโบราณและภาษาตะวันออก ดำรงตนอยู่บนความสมถะ เรียบง่าย
        ปฏิบัติหน้าที่ด้วยความเอาใจใส่ ยึดมั่นในความถูกต้อง
        ข้อเท็จจริงตามที่ปรากฏจากหลักฐาน ตลอดจนให้ความรู้
        แบ่งปันและถ่ายทอดประสบการณ์ให้กับอนุชนคนรุ่นใหม่ อย่างไม่เหน็ดเหนื่อย
        ท่านให้ความเมตตาและมีไมตรีจิตกับลูกศิษย์ลูกหา ผู้สนใจใฝ่รู้ ทุกเพศ
        ทุกวัย เป็นที่น่ายกย่องเชิดชู ปัจจุบัน อาจารย์เทิม มีเต็ม
        ยังคงปฏิบัติหน้าที่ที่หอสมุดแห่งชาติ ในการอ่านและปริวรรตคัมภีร์ใบลาน
        ต้นฉบับตัวเขียน และจารึกต่างๆ
      </p>

      <p class="q-title">4. ประวัติความเป็นมาของแม่น้ำอิง</p>
      <p class="indent mg-t-less mg-b-less">
        แม่น้ำอิง
        เป็นแม่น้ำสายหลักของภาคเหนือตอนบนมีต้นกำเนิดจากดอยหลวงหรือเทือกเขาผีปันน้ำจากป่าต้นน้ำ
        เป็นสายน้ำแห่งชีวิต
        เป็นเส้นเลือดใหญ่หล่อเลี้ยงหลายชุมชนในสองจังหวัดที่แม่น้ำนี้ไหลผ่าน คือ
        จังหวัดพะเยาและจังหวัดเชียงราย ก่อนจะไหลลงแม่น้ำโขงที่บ้านปากอิง
        อำเภอเชียงของ จังหวัดเชียงราย
        ตลอดเส้นทางที่แม่น้ำสายนี้ไหลผ่านรวมความยาวถึง 260 กิโลเมตร โดยแม่น้ำอิง
        เป็น 1 ใน 5 แม่น้ำของประเทศไทย
        ที่กระแสการไหลย้อนของน้ำเป็นไปในทิศทางจากใต้ขึ้นเหนือ
        เป็นแหล่งเพาะพันธุ์ปลาตามธรรมชาติ เป็นพื้นที่ชุ่มน้ำ
        เป็นแก้มลิงตามธรรมชาติ เป็นแหล่งรวบรวมพืชอาหารและพืชสมุนไพร
        และยังเป็นพื้นที่อยู่อาศัยของฝูงนกอพยพที่แวะเวียนมาพักพิงในแต่ละช่วงฤดูกาล
      </p>
      <p class="indent mg-t-less mg-b-less">
        ในด้านประวัติศาสตร์ ได้มีการกล่าวถึงดินแดนที่เรียกว่า “โยนก”
        ตั้งอยู่ในที่ราบลุ่มแม่น้ำโขง เป็นถิ่นกำเนิดของพวกยวน
        ปัจจุบันอยู่ในเขตจังหวัดเชียงราย
        โดยมักษณะเป็นที่ราบลุ่มกระจายอยู่ตามหุบเขา มีแม่น้ำสำคัญที่ไหลผ่านได้แก่
        แม่น้ำอิง แม่น้ำกก แม่น้ำลาว ไหลลงสู่น้ำโขง ในทางภูมิศาสตร์
        แม่น้ำอิงทางด้านเหนือกับด้านตะวันออกเฉียงเหนือของกว๊านพะเยา
        เป็นเนินสูงลาดลงมาจากเทือกเขาเหมาะสำหรับสร้างบ้านแปงเมือง
        เพราะน้ำท่วมไม่ถึง เป็นชัยภูมิชั้นเลิศ
        เป็นปราการตามธรรมชาติที่สามารถป้องกันข้าศึกได้เป็นอย่างดี
        ดังนั้นจึงพบซากเมืองโบราณและชุมชนโบราณหลายแห่ง เช่น เวียงพะเยา
        เวียงปู่ล่าม เวียงแก้ว เวียงต๋อม เป็นต้น
      </p>
      <p class="indent mg-t-less mg-b-less">
        นอกจากนี้ทางด้านตะวันออกของกว๊านพะเยา ยังเป็นที่ราบลุ่มระหว่างหุบเขา
        พบซากโบราณสถานเวียงลอ ปัจจุบันตั้งอยู่ในเขตอำเภอจุน จังหวัดพะเยา
        โดยเมืองโบราณแห่งนี้ มีแม่น้ำอิงไหลผ่านกลางเมือง มีสถูปเจดีย์ก่อด้วยอิฐ
        และพบโบราณวัตถุ ประเภทพระพุทธรูปหินทราย
        รูปแบบศิลปกรรมสกุลช่างพะเยามากมาย
      </p>
      <p class="indent mg-t-less mg-b-less">
        ต่อจากนั้นแม่น้ำอิง จะไหลขึ้นไปทางเหนือผ่านอำเภอเทิง จังหวัดเชียงราย
        บริเวณนี้มีที่ราบลุ่มกว้างใหญ่ มีเวียงเทิงตั้งอยู่ริมพระธาตุจอมจ้อ
        ทางด้านตะวันตกมีพระธาตุจอมซิ่น ซึ่งเป็นสถูปเก่าแก่โบราณ
        บริเวณนี้มีน้ำแม่อิงและแม่น้ำอีกหลายสาขาที่ไหลมาจากเทือกเขาทางด้านตะวันออกที่กั้นเขตชายแดนไทย-ลาว
        มีชุมชนโบราณอีกแห่งหนึ่งคือ เวียงเชียงคำ จะอยู่ในหุบเขาเล็กๆ
        ที่มีแม่น้ำลาวเป็นลำน้ำสาขาของแม่น้ำอิงไหลผ่าน
        จากเทือกเขาด้านใต้ไปลงแม่น้ำอิงบริเวณใกล้กับเมืองเทิง
      </p>
      <p class="indent mg-t-less mg-b-less">
        จากอำเภอเทิง
        แม่น้ำอิงไหลขึ้นไปทางเหนือเป็นที่ราบลุ่มในหุบเขาที่แม่น้ำอิงไหลผ่านลงไปออกแม่น้ำโขงในเขตบ้านปากอิง
        เหนือบริเวณปากอิงไปตามแม่น้ำโขง ประมาณ 7 กิโลเมตร
        เป็นที่ตั้งของเมืองเชียงของ เป็นชุมชนรูปกลมรี มีคูน้ำและกำแพงดินล้อมรอบ
        ตั้งอยู่บนเชิงเขาริมแม่น้ำโขง เมืองเชียงของเป็นเมืองปลายสุดของลุ่มน้ำอิง
        ปรากฏโบราณสถานมีวัดวาอารามหลายแห่ง
        บางแห่งมีพระพุทธรูปหินทรายลักษณะสกุลช่างพะเยา
      </p>
      <p class="indent mg-t-less mg-b-less">
        จึงถือว่าแม่น้ำอิง เป็นแม่น้ำสำคัญสายหลักของภาคเหนือตอนบน
        ตามที่ปรากฏหลักฐาน ร่องรอยเก่าแก่มากมาย และถูกใช้งานต่อเนื่อง
        เป็นที่ตั้งของชุมชนมากมาย ต่อเนื่องจนถึงปัจจุบัน
        สามารถใช้เป็นแรงบันดาลใจในการออกแบบชุดแบบอักษร TH TermM UP และ EN TermM
        UP ได้เป็นอย่างดี
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      previewImage: [require("../../assets/product/ng1-1.jpg")],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/element-variables.scss";
#product {
  padding: 40px 10vw 40px 10vw;
  text-align: center;
  min-height: 20vw;
  h1 {
    font-size: 30px;
    margin: 40px 0 10px 0;
    letter-spacing: 0;
  }
  .writtenBy {
    text-align: right;
    font-style: italic;
    font-size: 14px;
  }
  .image-title {
    padding: 20px 0;
    text-align: center;
    .el-image {
      height: 400px;
    }
    @media (max-width: 767px) {
      .el-image {
        height: 200px;
      }
    }
  }
  .detail {
    text-align: justify;
    word-break: break-all;
    .indent {
      text-indent: 3em;
    }
    .q-title {
      font-weight: 600;
      margin-bottom: 10px;
      text-align: left;
    }
    h3 {
      text-align: left;
    }
    .des {
      text-align: center;
    }

    a {
      cursor: pointer;
      color: $--color-text-primary;
      text-decoration: none;
    }
    a:hover {
      color: $--color-primary;
    }

    .card-product {
      padding: 10px 0;
      overflow: hidden;
      cursor: pointer;
      text-align: center;
      .el-image {
        height: 300px;
        width: 100%;
      }

      @media (max-width: 767px) {
        height: auto;
        padding: 10px 0;
      }
    }

    .card-product:hover {
      p {
        color: $--color-primary;
      }
    }

    .video {
      text-align: center;
    }
  }
}
</style>