<template>
  <DashboardTemplate >
      <ProductCP1 v-if="$route.params.id === 'cp1'"/>
      <ProductCP2 v-if="$route.params.id === 'cp2'"/>
      <ProductCP3 v-if="$route.params.id === 'cp3'"/>
      <ProductCP4 v-if="$route.params.id === 'cp4'"/>
      <ProductCP5 v-if="$route.params.id === 'cp5'"/>
      <ProductCP6 v-if="$route.params.id === 'cp6'"/>
      <ProductNG1 v-if="$route.params.id === 'ng1'"/>
  </DashboardTemplate>
</template>


<script>
import DashboardTemplate from '@/template/DashboardTemplate.vue'
import ProductCP1 from '@/components/product/ProductCP1.vue'
import ProductCP2 from '@/components/product/ProductCP2.vue'
import ProductCP3 from '@/components/product/ProductCP3.vue'
import ProductCP4 from '@/components/product/ProductCP4.vue'
import ProductCP5 from '@/components/product/ProductCP5.vue'
import ProductCP6 from '@/components/product/ProductCP6.vue'

import ProductNG1 from '@/components/product/ProductNG1.vue'

export default {
  components: {
    DashboardTemplate,
    ProductCP1,
    ProductCP2,
    ProductCP3,
    ProductCP4,
    ProductCP5,
    ProductCP6,
    ProductNG1
  },
  created(){
    window.scrollTo(0,0)
  }
}
</script>

